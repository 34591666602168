import React from "react"
import Layout from "../components/Layout.js"

export default () => {
  return (
    <Layout>
      <main className="max-w-4xl flex-grow mx-auto flex flex-col justify-around">
      <div 
            dangerouslySetInnerHTML={{
            __html: `
            <a rel="license" href="http://creativecommons.org/licenses/by/4.0/"><img alt="Creative Commons License" style="border-width:0" src="https://i.creativecommons.org/l/by/4.0/88x31.png" /></a><br />HMD Italia web site (including text and photos) is licensed by H.M.D. Italia Società Sportiva Dilettantistica a R.L.S. under a <a rel="license" href="http://creativecommons.org/licenses/by/4.0/">Creative Commons Attribution 4.0 International License</a>.`}} />
      </main>
    </Layout>
  )
}
